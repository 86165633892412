<template>
  <div class="QueryMonth">
    <a ref="download" style="display: none" />
    <AttendanceMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参建单位">
          <el-select
            size="small"
            v-model="currentUnit"
            placeholder="请选择参建单位"
            clearable
          >
            <el-option
              v-for="item in unitList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门班组">
          <el-select
            size="small"
            v-model="currentDepartment"
            placeholder="请选择部门/班组"
            clearable
          >
            <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.labourGroupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="统计范围">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :clearable="false"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="queryMonthListData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportDate('page')"
        >导出当前数据</el-button
      >
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportDate('all')"
        >导出全部数据</el-button
      >
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <el-table :data="queryMonthList" style="width: 100%" @row-click="queryItemListData">
        <el-table-column>
          <template slot-scope="scope">
            <img :src="scope.row.idPhoto" width="102px" height="126px">
          </template>
        </el-table-column>
        <el-table-column prop="wgName" label="姓名"></el-table-column>
        <el-table-column prop="wgCordNo" label="员工工号"></el-table-column>
        <el-table-column prop="groupName" label="部门/班组"></el-table-column>
        <el-table-column prop="attendance" label="出勤(次)"></el-table-column>
        <el-table-column prop="beLate" label="迟到(次)"></el-table-column>
        <el-table-column prop="leaveEarly" label="早退(次)"></el-table-column>
        <el-table-column prop="noAttendance" label="未出勤(次)"></el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[1, 5, 10, 20, 50]"
        :total="totals"
        @current-change="queryMonthListData"
        @size-change="queryMonthListData"
      >
      </el-pagination>
    </div>

    <!-- 个人详情 -->
    <el-dialog :title="daterange[0]+' 至 '+daterange[1]+' 出勤详情'" :visible.sync="showQueryItemList" custom-class="queryItemList">
      <div class="queryItemListWrap">
        <div class="queryItemListContent" :style="queryItemList.length * 100 +'px'">
          <div v-for="(item, index) in queryItemList" :key="index" 
            :class="[
              item.attendanceStatus.length ? '' : 'queryItemListItem',
              item.attendanceStatus.length == 1 && item.attendanceStatus[0] == 4 ? 'queryItemListItemOk' : '',
              item.attendanceStatus.length && item.attendanceStatus[0] != 4 ? 'queryItemListItemErr' : '',
            ]"
          >
            <p>{{item.attendanceDay}}</p>
            <p>{{getAttendanceStatus(item.attendanceStatus)}}</p>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<style lang="less" scope>
.QueryMonth {
  .el-form-item {
    margin-bottom: 0;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 260px;
  }
  .el-range__icon {
    line-height: 25px;
  }
  .el-input__inner {
    height: 32px;
  }
  .el-date-editor .el-range-separator {
    line-height: 25px;
  }
  .queryItemList {
    width: 1000px;
    .queryItemListWrap {
      width: 100%;
      .queryItemListContent {
        display: flex;
        flex-wrap: wrap;
        .queryItemListItem {
          width: 100px;
          background-color: #e4e4e4;
          margin: 5px;
          padding: 5px;
          text-align: center;
        }
        .queryItemListItemOk {
          width: 100px;
          background-color: #a3f8a7;
          margin: 5px;
          padding: 5px;
          text-align: center;
        }
        .queryItemListItemErr {
          width: 100px;
          background-color: #fdc59f;
          margin: 5px;
          padding: 5px;
          text-align: center;
        }
      }
    }
  }
}
</style>

<script>
import AttendanceMenu from "@/components/AttendanceMenu.vue";

export default {
  name: "QueryDay",
  components: {
    AttendanceMenu,
  },
  data() {
    return {
      currentProject: null,
      currentUnit: null,
      currentDepartment: null,
      // daterange: ['2022-01-01', '2022-01-31'],//this.getNowFormatDay(),
      daterange: this.getNowFormatDay(), // 默认选择上个自然月

      projectList: [],
      unitList: [],
      departmentList: [],

      queryMonthList:[],

      queryItemList:[],
      showQueryItemList: false,
      pageNum: 1,
      pageSize: 10,
      totals: 0,
    };
  },
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (val) {
      this.currentUnit = null;
      this.pageNum = 1;
      this.getUnitList(); // 加载单位列表数据
      this.queryMonthListData()
    },
    currentUnit: function (val) {
      this.currentDepartment = null;
      this.pageNum = 1;
      this.getDepartmentList(); // 加载部门班组列表数据
      this.queryMonthListData()
    },
    currentDepartment: function (val) {
      this.pageNum = 1;
      this.queryMonthListData()
    },
    daterange: function (val) {
      this.pageNum = 1;
      this.queryMonthListData()
    },
  },
  computed: {
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.radio = null;
      that.currentUnit = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载单位列表数据
    getUnitList: function () {
      var that = this;
      that.radio = null;
      var data = {
        companyType: "",
        companyName: "",
        address: "",
        projectId: that.currentProject,
        pageNum: 1,
        pageSize: "",
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/dept/InquireCompany",
          data: data,
        })
        .then((res) => {
          console.log(res);
          that.unitList = res.data.depts;
          // if (res.data.depts.length) {
          //   that.currentUnit = res.data.depts[0].id;
          // }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取部门/班组列表数据
    getDepartmentList: function () {
      var that = this;
      that.radio = null;
      var data = {
        labourGroupName: "",
        projectId: that.currentProject,
        deptId: that.currentUnit,
        pageNum: 1,
        pageSize: 100,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/labourgroup/labourGroupSearch",
          data: data,
        })
        .then((res) => {
          console.log("getDepartmentList =>", res);
          that.totals = res.data.totals;
          that.departmentList = res.data.labourGroupList;
          // if (res.data.labourGroupList.length) {
          //   that.currentDepartment = res.data.labourGroupList[0].id;
          // }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取月统计考勤数据
    queryMonthListData: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        companyId: this.currentUnit,
        groupId: this.currentDepartment,
        startTime: this.daterange[0],
        endTime: this.daterange[1],
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgAttendanceOa/getConsumerMonth",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.queryMonthList = res.data.wgConsumerMonthList
          that.totals = res.data.totals
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取个人详细统计数据
    queryItemListData: function (row) {
      console.log('queryItemListData =>', row);
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        wgCordNo: row.wgCordNo,
        startTime: this.daterange[0],
        endTime: this.daterange[1],
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgAttendanceOa/getCMonthOne",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log('queryItemListData =>', res);
          that.queryItemList = res.data
          that.showQueryItemList = true
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取当前默认日期范围，选择当前日期的上个自然月，格式YYYY-MM-DD
    getNowFormatDay: function (nowDate) {
        var char = "-";
        if(nowDate == null){
            nowDate = new Date();
        }
        var day = nowDate.getDate();
        var month = nowDate.getMonth() + 1;//注意月份需要+1
        var year = nowDate.getFullYear();

        var start = year + char + this.completeDate(month) + char + '01'
        var end = year + char + this.completeDate(month+1) + char + '01'

        var endDate = new Date(end)
        endDate.setTime(endDate.getTime()-24*60*60*1000)
        end = endDate.getFullYear()+"-" + (endDate.getMonth()+1) + "-" + endDate.getDate()

        return [start, end]
        //补全0，并拼接
        // return year + char + this.completeDate(month) + char + this.completeDate(day);
    },
    //补全0
    completeDate: function (value) {
        return value < 10 ? "0"+value : value;
    },
    // 转翻译出勤状态 <!-- 0出勤 1未出勤 2迟到 3早退 4全勤 -->
    // getAttendanceStatus: function (data) {
    //   if (!data) { return }
    //   var list = data.split(",")
    //   console.log('getAttendanceStatus =>', list);
    //   var result = ''
    //   list.forEach(item => {
    //     if (item == 0) { result += '出勤 ' }
    //     if (item == 1) { result += '未出勤 ' }
    //     if (item == 2) { result += '迟到 ' }
    //     if (item == 3) { result += '早退 ' }
    //     if (item == 4) { result += '全勤 ' }
    //   });
    //   return result
    // },
    getAttendanceStatus: function (value) {
      if (!value.length) {
        return '-'
      }
      var result = ''
      value.forEach(item => {
        if (item == 0) { result += '出勤 ' }
        if (item == 1) { result += '未出勤 ' }
        if (item == 2) { result += '迟到 ' }
        if (item == 3) { result += '早退 ' }
        if (item == 4) { result += '全勤 ' }
      });
      return result
    },
    // 导出数据
    exportDate: function (type) {
      var that = this;
      var timestamp = new Date().getTime(); 
      var data = {
        projectId: this.currentProject,
        companyId: this.currentUnit,
        groupId: this.currentDepartment,
        startTime: this.daterange[0],
        endTime: this.daterange[1],
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      if (!this.currentUnit) {
        data.companyId = ""
      }
      if (!this.currentDepartment) {
        data.groupId = ""
      }
      if (type == 'all') {
        data.pageNum = 1
        data.pageSize = ""
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axios({
          method: "post",
          url: "api/admin/wgAttendanceOa/CMonthDownload",
          data: data,
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          transformRequest: [
            function (data) {
              let ret = "";
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              return ret;
            },
          ],
        })
        .then((res) => {
          const blob = res.data;
          that.$refs.download.href = URL.createObjectURL(blob);
          that.$refs.download.download = `月统计表${timestamp}.xls`;
          that.$refs.download.click();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    }
  },
};
</script>
